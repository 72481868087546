import { render, staticRenderFns } from "./AppView.vue?vue&type=template&id=7236b31e&scoped=true"
import script from "./AppView.vue?vue&type=script&lang=js"
export * from "./AppView.vue?vue&type=script&lang=js"
import style0 from "./AppView.vue?vue&type=style&index=0&id=7236b31e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7236b31e",
  null
  
)

export default component.exports